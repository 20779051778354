<template>
  <layout-vertical>
    <router-view />

    <div
      slot="vertical-menu-header"
      class="d-flex align-items-center h-100"
    >
      <MenuHeader />
    </div>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="footer">
      <Footer />
    </div>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import MenuHeader from '../components/MenuHeader.vue'
import navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {
    LayoutVertical,
    MenuHeader,
    navbar,
    Footer
  }
}
</script>
