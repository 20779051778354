<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName }}
        </p>
        <span
          v-if="!['cliente'].includes(userData.role)"
          class="user-status"
        >{{ userData.role }}</span>
        <span
          v-else
        >
          {{ level.name }}
        </span>
      </div>
      <b-avatar
        v-if="!(userData.avatar === null || userData.avatar === '')"
        size="40"
        :src="`${$api_host}${userData.avatar}`"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      />
      <b-avatar
        v-else
        size="40"
        :src="require('@/assets/images/avatars/default.jpeg')"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item
      :to="{ name: 'Perfil'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Perfil</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Sair</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapState } from 'vuex'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  },
  data () {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText
    }
  },
  computed: {
    ...mapState('discountLevel', ['level'])
  },
  methods: {
    logout () {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    }
  }
}
</script>
