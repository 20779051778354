<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificações
        </h4>
        <b-badge
          v-if="notifications.length > 0"
          pill
          variant="light-primary"
        >
          {{ notifications.length }} Novo<span
            v-if="notifications.length > 1"
          >s</span>
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications.length > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="(item, index) in notifications"
        :key="index"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="item.avatar"
              :text="item.avatar"
              :variant="item.type"
            >
              <feather-icon :icon="item.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ item.title }}
            </span>
          </p>
          <small class="notification-text">{{ item.subtitle }}</small>
          <br>
          <small class="notification-text float-right font-weight-bolder text-dark">{{ item.created_at | date }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click.stop="markAllAsRead()"
      >Marcar todos como lido</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox
  },
  directives: {
    Ripple
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
    notifications: [],
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }
  }),
  mounted () {
    window.Echo.private(`user.${this.userData.id}`).notification(res => {
      this.notifications.push({
        ...res.data,
        created_at: res.created_at
      })

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: res.data.title,
          icon: res.data.icon,
          variant: res.data.type,
          text: res.data.subtitle
        }
      })
    })

    this.getNotifications()
  },
  methods: {
    async getNotifications () {
      await this.$http.get('/user/notifications/unread').then(res => {
        this.notifications = res.data.map(item => {
          return { ...item.data, created_at: item.created_at }
        })
      })
    },
    async markAllAsRead () {
      await this.$http
        .post('/user/notifications/unread/mark_as_read')
        .then(res => {
          this.notifications = res.data
        })
    }
  }
}
</script>

<style>
</style>
