<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col ~ Usado para colocar icones que só aparecem no desktop
      e são escondidos no celular -->
    <!-- Por enquanto, ainda não utilizado! -->
    <!-- <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
    </div> -->

    <div>
      <Balance />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <b-button
        v-if="hasOldLogin"
        class="mr-2"
        size="sm"
        variant="relief-primary"
        @click.stop="backToOldToken()"
      >
        Voltar
      </b-button>

      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BButton
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Balance from './Balance.vue'
import NotificationDropdown from './NotificationDropdown.vue'
import locale from './Locale.vue'
import UserDropdown from './UserDropdown.vue'
import logInPerId from '@/mixins/logInPerId'

export default {
  components: {
    BLink,
    BNavbarNav,
    BButton,

    // Navbar Components
    Balance,
    locale,
    NotificationDropdown,
    DarkToggler,
    UserDropdown
  },
  mixins: [logInPerId],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
