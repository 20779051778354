<template>
  <div
    class="font-weight-bolder text-success ml-2"
    @click.stop="$router.push({ name: 'Adicionar Saldo' })"
  >
    <a>{{ currency }}</a>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  computed: {
    ...mapState('user', [
      'user'
    ]),
    currency () {
      return currencyPrecision(this.user.balance, 2, 4)
    }
  },
  methods: {
    ...mapActions('user', ['setUser'])
  }
}
</script>
