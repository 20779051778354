<template>
  <div>
    <div class="mb-0 float-left">
      <a href="#">Contas Infinity.</a> &copy; Todos os direitos reservados
      {{ new Date().getFullYear() }}.
    </div>

    <div class="float-right">
      <b-link @click.stop="$router.push({ name: 'Termos' })">
        Termos e políticas de uso
      </b-link>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink
  }
}
</script>
